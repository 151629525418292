import React, { Component } from "react";
import { Grid, Table, Button } from "semantic-ui-react";
import TableHeader from "../data-table/table-header";
import TableRow from "../data-table/table-row";
import { connect, bindActionCreators, actions } from "../../../functional/global-import";

class DataTable extends Component {
  get initialState() {
    return {
      tableHeader: {
        // businessId: this.props.global.bussinessDetail.businessId, userId: this.props.auth.userDetail.keyId, businessLocationId: this.props.global.locationSelected.BusinessLocationId, 
        screenName: this.props.screenName, gridName: this.props.gridName,columns: [],actionPerformedBy: this.props.auth.loggedIn.emailId
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = { ...this.initialState, columnFilter: [], columnsList: [], userDefinedColumns: [], localUserDefinedColumns: [] };
  }

  ///////////// --TABLE HEADING DROPDOWN CHECKBOX FUNCTION-- /////////////
  onHandleChange = (e, { checked, index }) => {
    let { localUserDefinedColumns } = this.state;
    if (checked) {
      localUserDefinedColumns[index]["CD"] = 1;
    }
    if (!checked) {
      localUserDefinedColumns[index]["CD"] = 0;
    }
    this.setState({ localUserDefinedColumns });
    this.setState(() => ({
      tableHeader: { ...this.state.tableHeader, columns: this.state.localUserDefinedColumns }
    }));
  };

  ///////////// --TABLE HEADING DROPDOWN DONE FUNCTION-- /////////////
  onHandleHiddenColumn = () => {
    this.props.actions.apiCall({
      urls: ["POSTCOMMONTABLE"],
      method: "POST",
      data: this.state.tableHeader,
      onSuccess: (response) => {
        this.setState(() => ({
          tableHeader: { ...this.state.tableHeader, columns: this.state.localUserDefinedColumns }
        }));
        this.setState({ userDefinedColumns: JSON.parse(JSON.stringify(this.state.localUserDefinedColumns)) })
      },
    });

  };

  ///////////// --GET API FOR TABLE-- /////////////
  componentDidMount = () => {
    
    this.props.actions.apiCall({
      urls: ["COMMONGRID"],
      method: "GET",
      data: {
        // businessId: this.props.global.bussinessDetail.businessId, userId: this.props.auth.userDetail.keyId, businessLocationId: this.props.global.locationSelected.BusinessLocationId,
        screenName: this.props.screenName,
        gridName: this.props.gridName,
      },
      onSuccess: (response) => {
        
        this.setState({ columnsList: JSON.parse(response[0].systemDesfinedGridColumns), userDefinedColumns: JSON.parse(response[0].userDefinedGridColumns), localUserDefinedColumns: JSON.parse(response[0].userDefinedGridColumns) });
        this.setState(() => ({
          tableHeader: { ...this.state.tableHeader, columns: JSON.parse(response[0].systemDesfinedGridColumns) }
        }));

      },
    });
  }

  render() {
    const { columns, onHandleSorting, sortColumn, sortingArrow, data, hasMore, fetchMoreData, emptyGrid, gridName, gridDataLoading, tableHeight } = this.props;
    return (
      <Grid>
        <Grid.Column width={16}>
          <div className="common-table">
            <Table className="scroll-table commonTableDropdown" id={gridName} singleLine>
              <TableHeader
                columns={columns}
                columnsList={this.state.columnsList}
                onHandleSorting={onHandleSorting}
                sortingArrow={sortingArrow}
                sortColumn={sortColumn}
                onChange={this.onHandleChange}
                hideColumns={this.state.userDefinedColumns}
                dropdownCheck={this.state.localUserDefinedColumns}
                onHandleHiddenColumn={this.onHandleHiddenColumn}
                gridName={gridName}
              />
              <TableRow singleLine
                data={data}
                columns={columns}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                emptyGrid={emptyGrid}
                hideColumns={this.state.userDefinedColumns}
                gridName={gridName}
                gridDataLoading={gridDataLoading}
                tableHeight={tableHeight}
              />
            </Table>
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}
const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch)
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
