import React, { Component } from "react";
import { Select, Icon, List, Grid, Dropdown, Container, Checkbox, Input, Image } from "semantic-ui-react";
import { Link, env, connect, commonFunctions, bindActionCreators, actions, withRouter } from "../../../functional/global-import";
const trigger = (
  <span>
    <span className="iconify" data-icon="akar-icons:circle-plus" data-inline="false"></span>
  </span>
)
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemStatus: false,
      orderStatus: false,
      memberStatus: false,
      locationOption: [],
      selectedLocation: "",
    };
  }

  componentDidMount() {

  }
  onNext = (step) => {
    this.setState({ activeStep: step });
  };
  onPrevious = (step) => {
    this.setState({ activeStep: step });
  };
  itemModal = () => {
    this.setState({
      itemStatus: !this.state.itemStatus,
    });
  };
  orderModal = () => { // New Order Modal set Status
    this.setState({
      orderStatus: !this.state.orderStatus,
      ViewCustomerPanel: false,
    });
    this.props.actions.storeCustomerDetail();
  };

  AddActivityModal = () => {
    this.setState({
      activityStatus: !this.state.activityStatus,
    });
  };

  NewMemberModal = () => {
    this.setState({
      memberStatus: !this.state.memberStatus,
    });
  }

  BundleAddModal = () => {
    this.setState({
      bundleStatus: !this.state.bundleStatus,
    });
  };
  onHandleDropdown = (e, { name, value, type, checked, data, id }) => {
    let selecteLocation = JSON.parse(this.props.auth.userDetail.businessUserLocations).filter(location => location.BusinessLocationId === parseInt(e.currentTarget.id));
    if (window.location.pathname.indexOf("inventory") > 0) {
      this.props.history.push(`${env.PUBLIC_URL}/inventory`);
    }
    else {
      this.props.history.push(window.location.pathname);
    }

    this.props.actions.storeLocationSelected({ BusinessLocationId: e.currentTarget.id, value: value, BusinessLocationName: value, Zipcode: selecteLocation[0].Zipcode });
  }
  render() {
    const { global } = this.props;
    return (
      <React.Fragment>
        <div className="header-top">
          <Container fluid>
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <div className="d-flex">
                    <div className="tabImage">
                      <Image src={commonFunctions.concatenateImageWithAPIUrl(global.bussinessDetail.businessLogo)}
                        title={global.bussinessDetail.businessName} size="mini" inline className="tabshow" />
                      <Link onClick={() => { this.props.onTabToggle() }} className="tabshow orange-color">
                        <Icon name="bars" size='large' className="menubar" />
                        <Icon name="close" size='large' className="close" />
                      </Link>
                    </div>

                  </div>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center" width={16}>
                  {/* <h2>Header</h2> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
        <div className="sectionBg"></div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    global: state.global,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeLocationSelected: bindActionCreators(actions.storeLocationSelected, dispatch),
      storeCustomerDetail: bindActionCreators(actions.storeCustomerDetail, dispatch),
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));