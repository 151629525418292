import React, { Component } from 'react';
import SubscriptionForm from './subscription-form'
import SubscriptionList from './subscription-list'
import Package from './package'

class AddSubscription extends Component {
    render() {
        return (
            <React.Fragment>
                <Package />
            </React.Fragment>
        );
    }
}

export default AddSubscription;
