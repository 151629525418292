import React, { Component } from "react";
import { Button, Modal, Grid } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

class CustomDateChoose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      time: "",
      dateTime: "",
      dateTime2: "",
      datesRange: "",
    };
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  render() {
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
      >
        <Modal.Header>Choose Custom Date</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>
              <Grid.Column width={8}>
                <DateInput
                  name="dateTime"
                  placeholder="From"
                  value={this.state.dateTime}
                  iconPosition="right"
                  label="Start Date &nbsp; "
                  onChange={this.handleChange}
                  closable
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <DateInput
                  name="dateTime2"
                  placeholder="To"
                  value={this.state.dateTime2}
                  iconPosition="right"
                  label="End Date &nbsp; "
                  onChange={this.handleChange}
                  closable
                />
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.closeModal} className="blue-btn">
            Back{" "}
          </Button>
          <Button onClick={this.props.closeModal} className="orange-btn">
            Save{" "}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CustomDateChoose;
