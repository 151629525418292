import { lazy } from 'react';
import { env } from '../../shared/functional/global-import';

// Configure all routes of the application that contain authenticate template
const AuthenticateTemplateRoutesConfing = [
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/dashboard`,
    component: lazy(() => import('../../pages/dashboard'))
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/change-password`,
    component: lazy(() => import('../../pages/password'))
  },
  // {
  //   exact: true,
  //   private: true,
  //   path: `${env.PUBLIC_URL}/user-setting`,
  //   component: lazy(() => import('../../pages/user-setting'))
  // },
  // {
  //   exact: true,
  //   private: true,
  //   path: `${env.PUBLIC_URL}/reset-password`,
  //   component: lazy(() => import('../../pages/reset-password-page'))

  // },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/crm`,
    component: lazy(() => import('../../shared/components/organisms/crm'))

  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/subscription`,
    component: lazy(() => import('../../shared/components/organisms/subscription'))
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/email-subscription`,
    component: lazy(() => import('../../shared/components/organisms/email-subscription'))
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/report`,
    component: lazy(() => import('../../shared/components/organisms/reports'))

  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/faq`,
    component: lazy(() => import('../../pages/faq'))
  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/business-detail/:id`,
    component: lazy(() => import('../../shared/components/organisms/business-detail/business-detail'))

  },
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/packages`,
    component: lazy(() => import('../../shared/components/organisms/package'))

  },

];

export default AuthenticateTemplateRoutesConfing;