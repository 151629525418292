import React, { Component } from 'react';
import { Header, Sidebar } from '..';


class AuthenticateUserTemplate extends Component {
    constructor() {
        super();
        this.state = {
            toggleTabMenu: false,
            toggleHeaderMenu: false,
            toggleMenu: true
        };
    }
    changeMenu = () => {
        const { toggleMenu } = this.state;
        this.setState({ toggleMenu: !toggleMenu, toggleTabMenu: false });
    }

    changeHeaderMenu = () => {
        const { toggleHeaderMenu } = this.state;
        this.setState({ toggleHeaderMenu: !toggleHeaderMenu });
    }

    render() {
        const { toggleTabMenu, toggleHeaderMenu, toggleMenu } = this.state;
        const { children } = this.props;
        return (
            <div className={`App ${toggleMenu ? "menuExpanded" : "menuCollapsed"} ${toggleTabMenu ? "HeaderMenu" : ""} ${toggleHeaderMenu ? "HeaderMenu" : ""}`} >
                <div>
                    <Header onTabToggle={this.changeHeaderMenu} />
                    <Sidebar onToggle={this.changeMenu} onTabToggle={this.changeHeaderMenu} />
                </div>
                <div className="main-container">
                   {children}
                </div>
            </div>
        );
    }
}


export default AuthenticateUserTemplate;