import React, { Component } from "react";
import {
  Form,
  Grid,
  Header,
  Card,
  Icon,
  Input,
  Table,
  Checkbox,
  Loader,
} from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
  commonFunctions,
  Link,
  env,
} from "../../../functional/global-import";
import { CommonDeleteUpdateModal } from "../../../components";
import GoogleMapModal from "../../organisms/modal/google-map-modal.jsx";
import DataTable from "../data-table";

class Package extends Component {
  // Put all key & values that need to reset on form submit and reset form.
  get initialState() {
    return {
      updateAddToggle: {
        subscriptionId: "",
        isActive: false,
        actionPerformedBy: this.props.auth.userDetail.emailId,
      },
      packageList: {
        businessId: 1,
        subscriptionId: 1,
        businessSubscripitonId: -1,
        pageNo: 1,
        PageSize: 100,
        searchValue: "",
        SortColumn: "",
        SortOrder: "desc",
        sortingArrow: "sort",
        isActive: false,
        gridDataLoading: false,
      },
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      crmBoxDetail: [],
      customFields: false,
      advance: false,
      customHeader: false,
      isCommonDeleteUpdateModalOpen: false,
      updateButton: false,
      editDate: [],
      skeletonLoading: true,
      subscriptionDetail: [],
      openModal: false,
      hasMore: true,
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount() {
    this.getSubscriptionPackage();
  }
  getSubscriptionPackage = () => {
    this.props.actions.apiCall({
      urls: ["GETSUBSCRIPTIONPACKAGE"],
      method: "GET",
      data: this.state.packageList,
      onSuccess: (response) => {
        this.setState({ subscriptionDetail: response });
      },
    });
  };

  onHandleSorting = (heading) => {
    let sortOrder = this.state.packageList.SortOrder === "asc" ? "desc" : "asc";
    let sortArrow =
      this.state.packageList.sortingArrow === "sort up"
        ? "sort down"
        : "sort up";
    this.setState(
      {
        packageList: {
          ...this.state.packageList,
          SortColumn: heading,
          SortOrder: sortOrder,
          SortingArrow: sortArrow,
          pageNo: 1,
          pageSize: 100,
        },
      },
      () => {
        this.getSubscriptionPackage();
      }
    );
  };
  closeActiveInactiveModal = () => {
    this.setState({
      isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen,
    });
  };
  openActiveInactiveModal = (sub) => {
    const updateAddToggle = this.state;
    updateAddToggle.subscriptionId = sub.subscriptionId;
    updateAddToggle.isActive = sub.isActive;
    const activeInactive = sub.isActive ? "deactivate" : "activate";
    const messagetoShowInModal = sub.packageName;
    this.setState({
      updateAddToggle,
      buttonName: "Update",
      messageToShow:
        activeInactive +
        " " +
        '" ' +
        messagetoShowInModal +
        '" ' +
        " " +
        "business" +
        " ?",
      isCommonDeleteUpdateModalOpen: !this.state.isCommonDeleteUpdateModalOpen,
    });
  };
  updateActiveInactive = () => {
    if (this.state.buttonName === "Update") {
      const { updateAddToggle } = this.state;
      updateAddToggle.isActive = !this.state.updateAddToggle.isActive;
      this.setState({ updateAddToggle });
      this.props.actions.apiCall({
        urls: ["UPDATESUBSCRIPTIONTOGGLE"],
        method: "PUT",
        data: updateAddToggle,
        onSuccess: (response) => {
          this.getSubscriptionPackage(response);
          this.closeActiveInactiveModal();
        },
        showNotification: true,
      });
    }
  };
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var packageList = commonFunctions.onHandleChange(
      e,
      { name, value, type, checked, data },
      this.state.packageList
    );
    this.setState({ packageList }, () => this.getSubscriptionPackage());
  };
  openMap = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };
  fetchMoreData = () => {
    const countPageNo = this.state.subscriptionDetail.pageNo + 1;
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState(
        {
          subscriptionList: { ...this.state.packageList, pageNo: countPageNo },
        },
        () => {
          this.getSubscriptionPackage();
        }
      );
    }, 500);
  };
  render() {
    const {
      subscriptionDetail,
      openModal,
      packageList,
      hasMore,
      buttonName,
      isCommonDeleteUpdateModalOpen,
      messageToShow,
    } = this.state;
    const { api } = this.props;
    return (
      <div class="cmn-shad">
        <Form loading={api.isApiLoading}>
          <Grid>
            <Grid.Column width={16}>
              <Header as="h3">Packages</Header>
            </Grid.Column>
            <Grid.Column width={12}>
              <Input
                fluid
                icon="search"
                iconPosition="left"
                placeholder="Search"
                name="searchValue"
                data="searchValue"
                value={packageList.searchValue}
                className="common-search-bar"
                onChange={this.onHandleChange}
              />
            </Grid.Column>
            <Grid.Column width={16} className="pt-0">
              <Table.Body>
                <DataTable
                  gridName="PackageGrid"
                  screenName="Packages"
                  data={subscriptionDetail}
                  tableHeight="250px"
                  emptyGrid="No records to show"
                  onHandleSorting={this.onHandleSorting}
                  sortingArrow={packageList.sortingArrow}
                  sortColumn={packageList.sortColumn}
                  hasMore={hasMore}
                  fetchMoreData={this.fetchMoreData}
                  gridDataLoading={packageList.gridDataLoading}
                  columns={[
                    {
                      headerName: "Package Name",
                      fieldName: "packageName",
                      isSorting: true,
                    },
                    {
                      headerName: "Subscription Type",
                      fieldName: "subscriptionType",
                      isSorting: true,
                    },
                    {
                      headerName: "duration Type",
                      fieldName: "durationType",
                      isSorting: true,
                      textAlign: "right",
                    },
                    {
                      headerName: "Duration",
                      fieldName: "duration",
                      isSorting: true,
                      className: "textRight",
                    },
                    {
                      headerName: "Package Amount",
                      fieldName: "subscriptionFees",
                      isSorting: true,
                      className: "textRight",
                    },
                    {
                      headerName: "Active",
                      fieldName: "Active",
                      isSorting: false,
                      Cell: (props) => {
                        return (
                          <Checkbox
                            className="custom-toggle"
                            checked={props.isActive}
                            name="Update"
                            onClick={(data, r) =>
                              this.openActiveInactiveModal(
                                props,
                                (data = "IsActive"),
                                r
                              )
                            }
                          />
                        );
                      },
                    },
                  ]}
                />
              </Table.Body>
            </Grid.Column>
          </Grid>
        </Form>
        <CommonDeleteUpdateModal
          onUpdateModal={this.updateActiveInactive}
          buttonName={buttonName}
          messageToShow={messageToShow}
          isModalOpen={isCommonDeleteUpdateModalOpen}
          onCloseModal={this.closeActiveInactiveModal}
        />
        <GoogleMapModal openModal={openModal}
         closeModal={this.openMap} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Package);
