import React, { Component } from 'react';
import { Grid, Table, Icon } from 'semantic-ui-react';

class SubscriptionList extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="cmn-shad">
                    <Grid>
                        <Grid.Column width={16}>
                            <Table singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Package Name  <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                                        <Table.HeaderCell textAlign="right">Subscription Fees  <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">Subscription Type  <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                                        <Table.HeaderCell textAlign="center">Duration Type  <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                                        <Table.HeaderCell textAlign="right">Duration  <Icon link name="sort" className="orange-color" /></Table.HeaderCell>
                                        <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>test</Table.Cell>
                                        <Table.Cell textAlign="right">$100.00</Table.Cell>
                                        <Table.Cell  textAlign="center">abc</Table.Cell>
                                        <Table.Cell textAlign="center" >xyz</Table.Cell>
                                        <Table.Cell  textAlign="right">2</Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <Icon
                                                name="eye"
                                                className="maroon-color"
                                                title="view"
                                                link
                                                size="large"
                                            ></Icon>
                                            <Icon
                                                name="pencil alternate"
                                                className="deepviolet-color"
                                                title="Edit"
                                                link
                                                size="large"
                                            ></Icon>
                                            
                                            <Icon
                                                name="trash alternate"
                                                className="orange-color"
                                                title="Delete"
                                                link
                                                size="large"
                                            ></Icon>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default SubscriptionList;