import React, { Component } from 'react';
import { Grid, Header, Form,Button } from 'semantic-ui-react';


const Subscription = [
    { key: 'sub1', text: 'Subscription Type 1', value: 'subscriptiontype1' },
    { key: 'sub2', text: 'Subscription Type 2', value: 'subscriptiontype2' },
    { key: 'sub3', text: 'Subscription Type 3', value: 'subscriptiontype3' },
  ]

  const Duration = [
    { key: 'dur1', text: 'Duration Type 1', value: 'durationtype1' },
    { key: 'dur2', text: 'Duration Type 2', value: 'durationtyp2' },
    { key: 'dur3', text: 'Duration Type 3', value: 'durationtype3' },
  ]


class SubscriptionForm extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="cmn-shad">
                    <Form>
                        <Grid>
                            <Grid.Column width="16">
                                <Header as="h3">Add Subscription</Header>
                            </Grid.Column>
                            <Grid.Column width="8">
                                <Form.Input fluid label='Package Name' placeholder='Package Name' />
                            </Grid.Column>
                            <Grid.Column width="8">
                                <Form.Input fluid label='Subscription Fee' placeholder='Subscription Fee' />
                            </Grid.Column>
                            <Grid.Column width="8">
                                <Form.Select
                                    fluid
                                    label='Subscription Type'
                                    options={Subscription}
                                    placeholder='Subscription Type'
                                    className="custom-select"
                                />
                            </Grid.Column>
                            <Grid.Column width="8">
                                <Form.Select
                                    fluid
                                    label='Duration Type'
                                    options={Duration}
                                    placeholder='Duration Type'
                                    className="custom-select"
                                />
                            </Grid.Column>
                            <Grid.Column width="8">
                                <Form.Input fluid label='Duration' placeholder='Duration' />
                            </Grid.Column>
                            <Grid.Column width="8" textAlign='right' verticalAlign='bottom'>
                                <Button className="blue-btn">Cancel</Button>
                                <Button className="orange-btn">Save</Button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

export default SubscriptionForm;
