import React, { useState, useEffect } from 'react';
import GMap from './gmap';

// API key of the google map
const GOOGLE_MAP_API_KEY = 'AIzaSyBENat0ZBCb12nVXp1X0ms09MvQaA94zXY';

// load google map script
const loadGoogleMapScript = callback => {
    if (
        typeof window.google === 'object' &&
        typeof window.google.maps === 'object'
    ) {
        callback();
    } else {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', callback);
    }
};

const MapContainer = () => {
    const [loadMap, setLoadMap] = useState(false);

    useEffect(() => {
        loadGoogleMapScript(() => {
            setLoadMap(true);
        });
    }, []);

    return (
        <div className="googleMap">
            {!loadMap ? <div>Loading...</div> : <GMap />}
        </div>
    );
};

export default MapContainer;
