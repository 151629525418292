import React, { Component } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { env, Link, bindActionCreators, connect, actions } from "../../../../functional/global-import";
import GoogleMap from '../../../organisms/map/index'

class GoogleMapModal extends Component {
    // onHandleLogout = async () => {
    //     this.props.actions.logout();
    //     this.props.closeModal();
    // }
    render() {
        return (
            <Modal open={this.props.openModal}
             closeIcon
              onClose={this.props.closeModal} >
            <GoogleMap />
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            logout: bindActionCreators(actions.logout, dispatch),
        }
    };
};

export default connect(null, mapDispatchToProps)(GoogleMapModal);
