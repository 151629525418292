import React, { Component } from "react";
import { Button, Modal, Grid, Form, Input } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";

class AddAhoyCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ahoyDetail: {
        businessId: this.props.businessDetail.businessId,
        rehntitCharges: this.props.businessDetail.ahoyCharge,
        actionPerformedBy: this.props.auth.loggedIn.emailId,
      },
    };
  }

  handleChange = (e, { value }) => {
    debugger;
    this.setState({
      ahoyDetail: { ...this.state.ahoyDetail, rehntitCharges: value },
    });
  };
  updateAhoyCharge = () => {
    const { ahoyDetail } = this.state;
    this.props.actions.apiCall({
      urls: ["UPDATEAHOYCHARGE"],
      method: "PUT",
      data: ahoyDetail,
      onSuccess: (response) => {
        debugger;
        this.props.onCloseModal();
        window.location.reload();
      },
      showNotification: true,
    });
  };
  render() {
    const { ahoyDetail } = this.state;
    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        size="mini"
        onClose={this.props.onCloseModal}
      >
        <Modal.Header>Add Ahoy Charge</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>
              <label>
                <strong>Ahoy Fee</strong>
              </label>
              <Grid.Column width={16}>
                <Input
                  label={{ basic: true, content: "%" }}
                  labelPosition="right"
                  textAlign="right"
                  name="rehntitCharges"
                  placeholder="Ahoy Fee"
                  data="rehntitCharges"
                  value={ahoyDetail.rehntitCharges}
                  onChange={this.handleChange}
                  fluid
                />
               
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onCloseModal} className="blue-btn">
            Back{" "}
          </Button>
          <Button onClick={this.updateAhoyCharge} className="orange-btn">
            Update{" "}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAhoyCharge);
