import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';

class Report extends Component {
    render() {
        return (
            <div >
                <Grid>
                    <h2>Reports</h2>
                </Grid>
            </div>



        );
    }
}

export default Report;
