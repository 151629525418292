import React, { Component } from "react";
import { connect, Link, commonFunctions, bindActionCreators, actions, } from "../../../functional/global-import";
import { List, Image, Menu, Icon, } from "semantic-ui-react";
import * as env from "../../../../config/env.config";
import { DashboardImg, DashboardImg2, CRMImg1, CRMImg2, SubscriptionImg1, SubscriptionImg2, FaqImg1, FaqImg2, ReportImg1, ReportImg2, AddSubsImg1, AddSubsImg2, rehntitLogo, UserImg2, UserImg1 } from "../../../functional/global-image-import";



class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: ""
    };
  }
  BreakTimeModal = () => {
    this.setState({
      BreakTimeStatus: !this.state.BreakTimeStatus
    });
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleLogout = async () => {
    //  this.props.logoutUserPersist();
    //  this.props.actions.logout();
    this.props.actions.storeUserDetail({});
    this.props.actions.logout();
    // localStorage.clear();
    // this.props.history.push("/login");
  }

  render() {
    const { activeItem } = this.state;
    const { global, auth } = this.props;
   
    return (
      <div className="left-sidebar">
        <div className="logo-menu">
          <List horizontal>
            <List.Item as={Link} to={`${env.PUBLIC_URL}/dashboard`} className="company-logo">
              <Image src={rehntitLogo} title={"Rehntit"} size="tiny " centered />
            </List.Item>

            <List.Item as={Link}>
              <Icon name="bars" onClick={this.props.onToggle} size='large' />
            </List.Item>
          </List>
        </div>
        <div className="main-menu scrolling">
          <Menu vertical>
            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/dashboard`} name="dashboard" active={activeItem === "dashboard"}
              onClick={(e, { name }) => { this.props.onTabToggle(); this.handleItemClick(e, { name }); }}>
              {/* <Icon name="home"/> */}
              <Image src={DashboardImg} className="black-image" size="mini" />
              <Image src={DashboardImg2} className="orange-image" size="mini" />
              <span>Dashboard</span>
            </Menu.Item>
            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/crm`} name="crm" active={activeItem === "crm"}
              onClick={(e, { name }) => { this.props.onTabToggle(); this.handleItemClick(e, { name }); }}>
              {/* <Icon name="clipboard list" /> */}
              <Image src={CRMImg1} className="black-image" size="mini" />
              <Image src={CRMImg2} className="orange-image" size="mini" />
              <span> CRM</span>
            </Menu.Item>

            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/Subscription`} name="Subscription" active={activeItem === "Subscription"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}>
              <Image src={SubscriptionImg1} className="black-image" size="mini" />
              <Image src={SubscriptionImg2} className="orange-image" size="mini" />
              <span> Subscriptions</span>
            </Menu.Item>
            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/email-subscription`} name="Email-Subscription" active={activeItem === "Email-Subscription"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}>
              {/* <Icon name="user" />   */}
              <Image src={UserImg1} className="black-image" size="mini" />
              <Image src={UserImg2} className="orange-image" size="mini" />
              <span>Email Subscriptions</span>
            </Menu.Item>

            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/packages`} name="packages" active={activeItem === "packages"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}>
              <Image src={AddSubsImg1} className="black-image" size="mini" />
              <Image src={AddSubsImg2} className="orange-image" size="mini" />
              <span>Packages</span>
            </Menu.Item>
            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/report`} name="Report" active={activeItem === "Report"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}>
              <Image src={ReportImg1} className="black-image" size="mini" />
              <Image src={ReportImg2} className="orange-image" size="mini" />
              <span> Reports</span>
            </Menu.Item>
            <Menu.Item className="dashboard-list" as={Link} to={`${env.PUBLIC_URL}/faq`} name="faq" active={activeItem === "faq"}
              onClick={(e, { name }) => {
                this.props.onTabToggle();
                this.handleItemClick(e, { name });
              }}>
              <Image src={FaqImg1} className="black-image" size="mini" />
              <Image src={FaqImg2} className="orange-image" size="mini" />
              <span> FAQ's</span>
            </Menu.Item>
          </Menu>
        </div>



        {/*  task bar drop down */}

        <div className="profile-menu main-menu">
          <List selection verticalAlign="middle" className="profile-dropdown">
            <List.Item as={Link} to={`${env.PUBLIC_URL}/change-password`}>
              <Icon name="setting" />
              <List.Content>Setting</List.Content>
            </List.Item>
            <List.Item as={Link} to={`${env.PUBLIC_URL}/`} onClick={() => { this.props.onTabToggle(); this.handleLogout() }}>
              <Icon name="log out" />
              <List.Content>Log Out</List.Content>
            </List.Item>
          </List>
          <Menu vertical>
            <Menu.Item name="Andrew" onClick={this.handleItemClick}>
              <span>{auth.loggedIn.userName}</span>
              <Icon name="caret up" />
              {/* <span>{this.props.auth.userDetail.userName}</span> */}
              {/* <Image src={commonFunctions.concatenateImageWithAPIUrl(auth.userDetail.userImage)} size="tiny" verticalAlign="top" /> */}
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    global: state.global,
    auth: state.auth

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      // apiCall: bindActionCreators(actions.apiCall, dispatch),
      // storeBussinessDetail: bindActionCreators(actions.storeBussinessDetail, dispatch),
      logout: bindActionCreators(actions.logout, dispatch),
      storeUserDetail: bindActionCreators(actions.storeUserDetail, dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
