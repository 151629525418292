import React, { Fragment } from 'react';
import ContentLoader from "react-content-loader";
import {List } from 'semantic-ui-react';
const ActivityBoxSkeleton = (props) => {
    return (
        <Fragment>
            <List relaxed>
                <List.Item>
                    <ContentLoader
                        height={45}
                        width={190}
                        {...props} >
                        <rect x="70" y="12" rx="3" ry="3" width="100" height="7" />
                        <circle cx="25" cy="22" r="22" />
                        <rect x="70" y="24" rx="3" ry="3" width="100" height="5" />
                    </ContentLoader>
                </List.Item>
                <List.Item>
                </List.Item>
            </List>
        </Fragment>
    );
};


ActivityBoxSkeleton.propTypes = {
};
export default ActivityBoxSkeleton;
