import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Modal, Header } from "semantic-ui-react";
import { connect} from "../../../../functional/global-import";


  const CommonDeleteUpdateModal = ({messageToShow,onUpdateModal,buttonName,isModalOpen,onCloseModal, api}) => {
  return (
    <Modal open={isModalOpen} closeIcon closeOnDimmerClick={false} onClose={onCloseModal} size={"mini"}>
      <Modal.Content>
        <Form loading={api.isApiLoading} >
        <Grid>
          <Grid.Column width={16} textAlign="center">
            <Header as="h4" className="orange-color">
              Do you want to {messageToShow}  
            </Header>
          </Grid.Column>
          <Grid.Column width={16} textAlign="center"> 
            <Button className="blue-btn" onClick={onCloseModal}>Cancel</Button>
            <Button className="orange-btn" onClick={onUpdateModal}>{buttonName}</Button>
          </Grid.Column>
        </Grid>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    api: state.api,
  };
};

CommonDeleteUpdateModal.propTypes = {
  messageToShow: PropTypes.string.isRequired,
  onUpdateModal: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.bool.isRequired
};


export default (connect(mapStateToProps)(CommonDeleteUpdateModal));