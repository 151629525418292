import React, { Component } from 'react';
import { Grid, Header, Image, Table } from 'semantic-ui-react';
import BusinessImg from "../../../../assets/images/company-logo.png"
import Chart from "react-apexcharts";
import { connect, bindActionCreators, actions, Notifications, commonFunctions, Link, env } from "../../../functional/global-import";

class BusinessDetail extends Component {
    constructor(props) {
        // const id = 
        super(props);
        this.state = {
            businessDetail: {
                businessId: props.match.params.id,
                businessLocationId: -1,
                businessName: "",
                businessAddress: "",
                countryId: "",
                stateId: "",
                city: "",
                zipCode: "",
                numberOfLocations: "",
                companyPhoneNumber: "",
                website: "",
                emailId: "",
                image: "",
                firstName: "",
                lastName: "",
                userPhoneNumber: "",
                position: "",
                businessTags: []
            },
            businessInfo: [],
            series: [],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            download: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: 'straight',
                    dashArray: [0, 8, 5]
                },
                title: {
                    text: "Order by month",
                    align: "center",
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: [],
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        }
                    ]
                },
                grid: {
                    borderColor: '#f1f1f1',
                }
            },
        };
    }
    componentDidMount = () => {
        this.getBusinessdetail();
        this.getOrderByMonth();
    }
    getBusinessdetail = () => {
        this.props.actions.apiCall({
            urls: ["GETBUSINESSDETAIL"], method: "GET", data: { businessId: this.state.businessDetail.businessId },
            onSuccess: (response) => {
                this.setState({ businessInfo: response });
            }
        });
    }
    getOrderByMonth = () => {
        
        const { businessDetail } = this.state;
        // this.setState({ skeletonLoading: true });
        this.props.actions.apiCall({
            urls: ["GETDASHBOARDTOTALSALE"], method: "GET", data: { businessId: businessDetail.businessId, businessLocationId: -1 },
            onSuccess: (response) => {
                
                this.GraphData(response);
            }
        });
    }
    GraphData = (response) => {
        
        var seriesList = response.map((x) => { return { "name": x.type, "color": x.color, "data": x.data } });
        // this.setState({ series: seriesList })
        var category = response.map((x) => { return { "category": x.months } });
        
        this.setState({
            series: seriesList, options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: category[0].category
                }
            }
        })
    }
    render() {
        const { options, series, businessInfo } = this.state
        return (
            <React.Fragment>
                <div className="cmn-shad">
                    {businessInfo.map((info) => (
                        <Grid>
                            <Grid.Column width={16}>
                                <Header as="h3">{info.businessName}</Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <div className="businessDetailImg">
                                    {/* <Image src={info.image} /> */}
                                    <Image src={BusinessImg} />
                                </div>
                            </Grid.Column>

                            <Grid.Column width={12}>
                                <Table basic='very'>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>Business Enrolled Since</Table.Cell>
                                            <Table.Cell textAlign='right'>{commonFunctions.formattedDate(info.businessEnrolledSince)}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Address</Table.Cell>
                                            <Table.Cell textAlign='right'>{info.businessAddress}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Subscription Plan</Table.Cell>
                                            <Table.Cell textAlign='right'>{info.subscriptionPlan}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Last Subscription Paid</Table.Cell>
                                            <Table.Cell textAlign='right'>{commonFunctions.formattedDate(info.lastSubscriptionPaid)}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Next Subscription Due</Table.Cell>
                                            <Table.Cell textAlign='right'>{commonFunctions.formattedDate(info.nextSubscriptionDue)}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>

                        </Grid>
                    ))}
                </div>
                <div className="cmn-shad">
                    <Grid>
                        <Grid.Column width={16}>
                            <Chart
                                options={options}
                                series={series}
                                type="line"
                                height={470}
                            />
                        </Grid.Column>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        api: state.api,
        global: state.global,
        auth: state.auth
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            apiCall: bindActionCreators(actions.apiCall, dispatch),
            storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
            showNotification: bindActionCreators(Notifications.show, dispatch)
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetail);
